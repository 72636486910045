.sort-asc {
  transform: rotate(-90deg);
}
.sort-desc {
  transform: rotate(90deg);
}
.cursor-asc {
  cursor: n-resize;
}
.cursor-desc {
  cursor: s-resize;
}
/*# sourceMappingURL=src/components/pages/dashboard/components/CoinTable.css.map */