
















.display-flex
  display flex
  align-items center

.search-input
  padding 4px 1em 4px 2.6em
  background #ffffff
  border 1px solid rgba(34, 36, 38, 0.15)
  border-radius 0.28rem

.search-icon
  position absolute
  vertical-align middle
  padding-left 1em
  color rgba(34, 36, 38, 0.45)
