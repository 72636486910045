













@import '~@/styles/variables'

.beta
  color cream-can

